
.right-cuesheet-row{
    display:flex;
    width:100%;
    margin-bottom:6px;
}

.right-cuesheet-cell,
.right-cuesheet-cell-middle,
.right-cuesheet-cell-small{
    width:100%;
    border-radius:6px;
    border:1px solid #565656;
    cursor:pointer;
}

.right-cuesheet-cell{
    padding:3px 5px;
}

.right-cuesheet-cell-middle{
    padding:1px 4px;
}

.right-cuesheet-cell-small{
    padding:0px 2px;
}

.right-cuesheet-cell-start{
    background-color:#e7f6e9
}
.right-cuesheet-cell-start:hover{
    background-color:#dcebde
}

.right-cuesheet-cell-end{
    background-color:#e2e8f6
}
.right-cuesheet-cell-end:hover{
    background-color:#d0d6e5
}

.right-cuesheet-cell-turn{
    background-color:#ffffff
}
.right-cuesheet-cell-turn:hover{
    background-color:#e5e5e5
}

.right-cuesheet-cell-turn-selected{
    background-color:#FEE6E2;
}
.right-cuesheet-cell-turn-selected:hover{
    background-color:#ECCEC9;    
}

.leaflet-popup-content{
    min-width:165px !important;
}

.leaflet-popup-close-button{
    display: none;
}

.leaflet-contextmenu-mobile .leaflet-popup-content{
    margin:0px !important;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out{
    width:36px !important;
    height:36px !important;
    line-height:36px !important;
    
}