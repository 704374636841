/* Google Fonts for Multi Language */

/* @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital@0;1&family=Kosugi+Maru&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital@0;1&family=Kosugi+Maru&Open+Sans:ital@0;1&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital@0;1&family=Kosugi+Maru&Open+Sans:ital@0;1&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital@0;1&Open+Sans:ital@0;1&family=Noto+Sans+JP:wght@100..900&display=swap');

/* Josefin Sans ( for English ) */
.font-en-cycroute,
.font-en-cycroute .MuiInputBase-input{
    font-family: "Josefin Sans", "Noto Sans JP", sans-serif !important;
    font-optical-sizing: auto !important;
    /* font-weight: 400 !important; */
    font-weight: 400;
    font-style: normal !important;
}

/* Kosugi Maru ( for Japanese ) */
.font-ja-cycroute,
.font-ja-cycroute .MuiInputBase-input{
    /* font-family: "Kosugi Maru", serif !important; */
    font-family: "Noto Sans JP", sans-serif !important;
    /* font-weight: 500 !important; */
    font-weight: 500;
    font-style: normal !important;
}

.font-en-serious-cycroute,
.font-en-serious-cycroute .MuiInputBase-input{
    font-family: "Open Sans", sans-serif !important;
    font-optical-sizing: auto !important;
    /* font-weight: 400 !important; */
    font-weight: 400;
    font-style: normal !important;
}

@media print {
    .no-print{
        display:none !important;
    }
}

@media screen {

    .cycroute-serious-text{
        color:#ffffff !important;
        text-shadow:
        0 0 4px #000000,
        0 0 4px #000000,
        0 0 5px #000000,
        0 0 7px #000000,
        0 0 9px #000000,
        2px 2px 7px #000000,
        2px 2px 7px #000000,
        2px 2px 7px #000000,
        2px 2px 7px #000000;
    }
    
    .cycroute-text{
        color:#ffffff !important;
        text-shadow:0 0 4px #000000,0 0 4px #000000,0 0 5px #000000,0 0 7px #000000,0 0 9px #000000,2px 2px 7px #000000;
    }
    
    .cycroute-text-link{
        color:#ffffff !important;
        text-shadow:0 0 4px #000000,0 0 4px #000000,0 0 5px #000000,0 0 7px #000000;
        text-underline-offset:4px;
    }

    .confirmation-text-link{
        color:#000000 !important;
        text-underline-offset:4px;
    }
    
}
