
@media screen {
    
    body.cycroute-mobile{
        /* スマホ用 */
        background-image:url('../src/image/pexels-bogdan-r-anton-338246-1088607_600x1800.webp');
    }
    body.cycroute-pc{
        /* PC/タブレット用 */
        background-image:url('../src/image/pexels-bogdan-r-anton-338246-1088607_1800x1200.webp');
    }
    
}

/* 共通 */
body{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

/* div#root{
    position:'relative';
} */